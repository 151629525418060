* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	font-family: "Poppins", sans-serif;
}

.step-wizard-list {
	color: #333;
	list-style-type: none;
	border-radius: 10px;
	display: flex;
	padding: 20px 10px;
	position: relative;
	z-index: 10;
}

.step-wizard-item {
	padding: 0 20px;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
	display: flex;
	flex-direction: column;
	text-align: center;
	min-width: 170px;
	position: relative;
}
.step-wizard-item + .step-wizard-item:after {
	content: "";
	position: absolute;
	left: 0;
	top: 19px;
	background: #21f28a;
	width: 100%;
	height: 2px;
	transform: translateX(-50%);
	z-index: -10;
}
.progress-count {
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	font-weight: 600;
	margin: 0 auto;
	position: relative;
	z-index: 10;
	color: transparent;
}
.progress-count:after {
	content: "";
	height: 40px;
	width: 40px;
	background: #40c652;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	z-index: -10;
}
.progress-count:before {
	content: "";
	height: 10px;
	width: 20px;
	border-left: 3px solid #fff;
	border-bottom: 3px solid #fff;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -60%) rotate(-45deg);
	transform-origin: center center;
}
.progress-label {
	font-size: 14px;
	font-weight: 600;
	margin-top: 10px;
}
.current-item .progress-count:before,
.current-item ~ .step-wizard-item .progress-count:before {
	display: none;
}
.current-item ~ .step-wizard-item .progress-count:after {
	height: 10px;
	width: 10px;
}
.current-item ~ .step-wizard-item .progress-label {
	opacity: 0.5;
}
.current-item .progress-count:after {
	background: rgb(211, 199, 199);
}
.current-item .progress-count {
	color: #f5f5f5;
}
