@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply min-h-screen;
    @apply font-roboto;
  }

  #root {
    @apply min-h-screen;
  }
}

/* width */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #002a4d;
  border-radius: 100vh;
  border: 2px solid #edf2f7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7893a1;
}

.Toastify__progress-bar--info {
  background: #56c271 !important;
}
.Toastify__progress-bar--success {
  background: #56c271 !important;
}

.Toastify__progress-bar--error {
  background: #f44336 !important;
}

.Toastify__progress-bar--warn {
  background: #ff9800 !important;
}

.carousel .control-next.control-arrow:before,
.carousel .control-prev.control-arrow:before {
  display: none !important;
}

#couponCarousel .carousel-dark .carousel-control-next-icon {
  margin-left: auto;
}

#couponCarousel .carousel-dark .carousel-control-prev-icon {
  margin-right: auto;
}

#couponCarousel .carousel-control-next,
#couponCarousel .carousel-control-prev {
  width: auto !important;
}

#discountCategorySelect div {
  border-width: 0;
}

#root {
  position: absolute;
  z-index: 1;
  width: 100%;
}
.slider-container {
  margin-top: 15px;
}

.slider {
  position: relative;
  width: 500px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #9fe5e1;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #dee2e6;
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 500px;
  outline: none;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}